<template>
  <div class="page-content">
    <!-- <div class="header">
      <router-link class="menu-item" to="/password">
        Modification
      </router-link>
    </div> -->
    <div class="content">
      <router-view />
      <h4>
        Modification de mot de passe
      </h4>
      <hr />
      <div v-if="initLoading" class="init-loading three-dots-loading">
        Chargement en cours
      </div>
      <form v-else @submit.prevent="handleSubmit()">
        <b-form-group label=" Nom & Prénom " label-for="nom-input">
          <b-form-input
            v-model="ChangePassword.Nom"
            id="nom-input"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group label="E-mail" label-for="email-input">
          <b-form-input
            v-model="ChangePassword.Email"
            id="email-input"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Nouveau mot de passe"
          label-for="nouveauPassword-input"
        >
          <b-form-input
            type="password"
            v-model="ChangePassword.password"
            id="nouveauPassword-input"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Confirmation de mot de passe"
          label-for="confirmPassword-input"
        >
          <b-form-input
            type="password"
            v-model="ChangePassword.password_confirmation"
            id="confirmPassword-input"
            required
          ></b-form-input>
        </b-form-group>
        <div class="message">
          <div v-if="loading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <div v-if="updated" class="success">Modifié!</div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <b-button type="submit" variant="success">
          Valider
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      ChangePassword: {
        Nom: null,
        Email: null,
        password: null,
        password_confirmation: null
      },
      loading: false,
      error: null,
      initLoading: true,
      updated: false
    }
  },
  methods: {
    ...mapActions(['updatePassword']),
    async handleSubmit() {
      this.loading = true
      this.updated = false
      this.error = null
      if (this.ChangePassword.password) {
        if (
          this.ChangePassword.password !=
          this.ChangePassword.password_confirmation
        ) {
          this.loading = false
          this.error = [
            {
              password:
                'le mot de passe et la confirmation du mot de passe doivent être égaux'
            }
          ]
          return
        }
        if (this.ChangePassword.password.length < 6) {
          this.loading = false
          this.error = [
            { password: 'le mot de passe doit contenir au moins 6 caractères' }
          ]
          return
        }
      }
      const response = await this.updatePassword(this.ChangePassword)
      if (response.success) {
        this.loading = false
        this.updated = true
      } else {
        this.loading = false
        this.error = response.error
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  async mounted() {
    this.initLoading = false
    this.ChangePassword.Nom =
      this.currentUser.first_name + ' ' + this.currentUser.last_name
    this.ChangePassword.Email = this.currentUser.email
    this.ChangePassword.id = this.currentUser.id
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .header {
    margin-top: 25px;
    .menu-item {
      float: left;
      display: block;
      margin-left: 5px;
      padding: 2px 11px 3px 11px;
      min-width: 8px;
      font-size: 14px;
      text-align: center;
      border: 1px solid #b9babb;
      border-bottom: 0;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      text-decoration: none;
      color: #26292c;
      cursor: pointer;
      &.router-link-active {
        background-color: #f8f8f8;
      }
    }
  }
  .content {
    background-color: #f8f8f8;
    padding: 10px;
    flex-grow: 1;
    .message {
      .error,
      .success,
      .loading {
        margin-left: 15px;
        padding-bottom: 20px;
      }
    }
  }
}
</style>
